define('ds-ember-sample/adapters/advertiser', ['exports', 'ds-ember-sample/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        urlForQuery(query) {
            return `${this.urlPrefix()}/v1/advertiser/search?skip=0&limit=10`;
        }
    });
});