define('ds-ember-sample/services/runtime-component-renderer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function detachedChildComponent(instance, element) {
    element.remove();

    let root = null;
    // eslint-disable-next-line no-underscore-dangle
    const roots = instance.renderer._roots;
    for (let i = 0; i < roots.length; i += 1) {
      const r = roots[i];
      if (r.isFor(instance)) {
        root = r;
        roots.splice(i, 1);
        break;
      }
    }

    (true && !(root !== null) && Ember.assert('Could not find the root for the component', root !== null));


    return { element, root };
  }

  exports.default = Ember.Service.extend({
    renderChildComponent(componentName, properties = {}) {
      const instance = this.lookup(componentName);

      return new Ember.RSVP.Promise((resolve, reject) => {
        const timeout = setTimeout(() => reject(), 5000);
        instance.on('didInsertElement', function () {
          clearTimeout(timeout);
          resolve(detachedChildComponent(instance, this.element));
        });
        Ember.setProperties(instance, properties);

        instance.append();
      });
    },

    lookup() {
      throw new Error('Overridden by initializer');
    }
  });
});