define('ds-ember-sample/models/advertiser', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        advertiserName: _emberData.default.attr(),
        orgName: _emberData.default.attr(),
        orgId: _emberData.default.attr()
    });
});