define('ds-ember-sample/components/item-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    simpleListColumns: Ember.computed(function () {
      return [{
        label: 'ID',
        property: 'id',
        width: 100
      }, {
        label: 'Name',
        property: 'name'
      }, {
        label: 'Count',
        property: 'childrenCount'
      }];
    }),
    simpleListData: Ember.computed(() => [{
      id: 94085,
      name: 'DMP > Disney > Anonymous',
      childrenCount: '--'
    }, {
      id: 94011,
      name: 'DMP > Disney > Cirque Du Soleil',
      childrenCount: '--'
    }, {
      id: 94043,
      name: 'DMP > Disney > Converted',
      childrenCount: '12 Children'
    }, {
      id: 94062,
      name: 'DMP > Disney > Cruiseline',
      childrenCount: '15 Children'
    }, {
      id: 94029,
      name: 'DMP > Disney > Lucasfilm',
      childrenCount: '18 Children'
    }, {
      id: 94031,
      name: 'DMP > Disney > Marvel Studios',
      childrenCount: '13 Children'
    }, {
      id: 94087,
      name: 'DMP > Paramount',
      childrenCount: '20 Children'
    }]),
    myData: Ember.computed(() => {
      var items = [];
      for (var i = 0; i < 10; i++) {
        items.push({ name: "Item " + i });
      }
      return items;
    }),

    actions: {
      itemChanged(item) {
        alert('clicking ' + item);
      },
      sort() {}
    }
  });
});