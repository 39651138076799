define('ds-ember-sample/helpers/tree-indent', ['exports', 'oath-ui-components/helpers/tree-indent'], function (exports, _treeIndent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _treeIndent.default;
    }
  });
  Object.defineProperty(exports, 'treeIndent', {
    enumerable: true,
    get: function () {
      return _treeIndent.treeIndent;
    }
  });
});