define('ds-ember-sample/serializers/advertiser', ['exports', 'ember-data', 'lodash'], function (exports, _emberData, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.JSONSerializer.extend({
        normalizeResponse(store, primaryModelClass, payload, id, requestType) {
            console.info('Payload: ' + JSON.stringify(payload));
            let data = _lodash.default.map(payload.advertiserList, (item, index) => {
                return {
                    id: index,
                    type: primaryModelClass.modelName,
                    attributes: {
                        advertiserName: item.advertiserName,
                        orgName: item.orgName,
                        orgId: item.orgId
                    }
                };
            });

            console.info('Data: ' + JSON.stringify(data));
            return {
                data: data
                // return {
                // data: [
                //     {
                //         id: 'dummy1',
                //         type: primaryModelClass.modelName,
                //         attributes: {
                //             advertiserName: 'blah blah blah',
                //             orgName: 'blah blah'
                //         }
                //     }
                // ]    
                //   data: {
                //     id: 'dummy',  
                //     type: type.modelName,
                //     attributes: {
                //         advertiserName: payload.advertiserList.orgName,
                //         orgName: 'blah blah'
                //     }
                //   }
            };
        }
        //   }
    });
});