define('ds-ember-sample/instance-initializers/runtime-component-renderer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    const dialogFactory = appInstance.lookup('service:runtime-component-renderer');
    dialogFactory.set('lookup', beanName => appInstance.lookup(beanName));
  }

  exports.default = {
    name: 'runtime-component-renderer',
    initialize
  };
});