define('ds-ember-sample/helpers/format', ['exports', 'oath-ui-components/helpers/format'], function (exports, _format) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _format.default;
    }
  });
  Object.defineProperty(exports, 'format', {
    enumerable: true,
    get: function () {
      return _format.format;
    }
  });
});