define('ds-ember-sample/components/app-nav', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        actions: {
            authenticateWithOC() {
                console.info("Authenticating...");
                this.get('session').authenticate('authenticator:oneCentral').then(() => {
                    this.set('msg', 'Authenticated');
                }).catch(reason => {
                    this.set('msg', reason);
                });
            },
            logout() {
                console.info('Logging out...');
                this.get('session').invalidate();
            }

        }
    });
});