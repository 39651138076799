define('ds-ember-sample/helpers/merge', ['exports', 'oath-ui-components/helpers/merge'], function (exports, _merge) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _merge.default;
    }
  });
  Object.defineProperty(exports, 'merge', {
    enumerable: true,
    get: function () {
      return _merge.merge;
    }
  });
});