define('ds-ember-sample/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'ds-ember-sample/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { JSONAPIAdapter } = _emberData.default;

  exports.default = JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service('session'),
    authorize(xhr) {
      let { access_token } = this.get('session.data.authenticated');
      if (Ember.isPresent(access_token)) {
        xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
      }
    },
    headers: _environment.default.API.headers,
    host: 'https://dmp-qai-taxonomy.advertising.aol.com',
    namespace: 'taxonomy'
  });
});