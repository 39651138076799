define('ds-ember-sample/services/dsapi', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        getItems() {
            return [{
                name: 'Tag 1',
                id: 'tag1'
            }, {
                name: 'Tag 2',
                id: 'tag2'
            }];
        }
    });
});