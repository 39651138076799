define('ds-ember-sample/authenticators/one-central', ['exports', '@ouroath/oath-ui-authentication/authenticators/one-central'], function (exports, _oneCentral) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _oneCentral.default;
    }
  });
});