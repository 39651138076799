define('ds-ember-sample/routes/items', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        dsapi: Ember.inject.service('dsapi'),
        // simpleListColumns: computed(() => ([
        //     {
        //       property: 'id',
        //       width: 100,
        //     },
        //     {
        //       property: 'name',
        //     },
        //     {
        //       property: 'childrenCount',
        //     },
        //   ])),
        // simpleListData: computed(() => (
        //   [
        //       {
        //         id: 94085,
        //         name: 'DMP > Disney > Anonymous',
        //         childrenCount: '--',
        //       },
        //       {
        //         id: 94011,
        //         name: 'DMP > Disney > Cirque Du Soleil',
        //         childrenCount: '--',
        //       },
        //       {
        //         id: 94043,
        //         name: 'DMP > Disney > Converted',
        //         childrenCount: '12 Children',
        //       },
        //       {
        //         id: 94062,
        //         name: 'DMP > Disney > Cruiseline',
        //         childrenCount: '15 Children',
        //       },
        //       {
        //         id: 94029,
        //         name: 'DMP > Disney > Lucasfilm',
        //         childrenCount: '18 Children',
        //       },
        //       {
        //         id: 94031,
        //         name: 'DMP > Disney > Marvel Studios',
        //         childrenCount: '13 Children',
        //       },
        //       {
        //         id: 94087,
        //         name: 'DMP > Paramount',
        //         childrenCount: '20 Children',
        //       },
        //     ]
        //   )
        // ),
        model() {
            return this.get('dsapi').getItems();
        },
        actions: {
            sort() {
                //dummy sort to make oa-list-builder works
            }
        }
    });
});