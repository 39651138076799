define('ds-ember-sample/initializers/microstates', ['exports', 'ember-microstates/initializers/microstates'], function (exports, _microstates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _microstates.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _microstates.initialize;
    }
  });
});