define('ds-ember-sample/components/oa-notifications--log-item/component', ['exports', 'oath-ui-components/components/oa-notifications--log-item/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _component.default;
    }
  });
});