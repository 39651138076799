define('ds-ember-sample/routes/advertisers', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model() {
            let advertiserColumns = [{
                label: 'Org ID',
                property: 'orgId'
            }, {
                label: 'Org Name',
                property: 'orgName'
            }, {
                label: 'Advertiser Name',
                property: 'advertiserName'
            }];
            let advertisers = this.store.query('advertiser', {});
            return {
                advertisers: advertisers,
                columns: advertiserColumns
            };
        },
        actions: {
            sort() {
                //dummy sort to make oa-list-builder works
            }
        }

    });
});