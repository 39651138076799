define('ds-ember-sample/routes/application', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ds-ember-sample/config/environment'], function (exports, _authenticatedRouteMixin, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        session: Ember.inject.service('session'),
        beforeModel(transition) {
            console.info('Inside beforeModel');
            // this._super(transition);
            this.get('session').on('authenticationSucceeded', () => {
                console.info('Session Authenticated');
            });

            this.get('session').on('invalidationSucceeded', () => {
                var logoutUrl = `${_environment.default['ember-one-central-authentication'].logoutUrl}?goto=${encodeURIComponent(window.location.href)}`;
                window.location.href = logoutUrl;
            });

            console.info('About to redirecting to login page');
            var that = this;
            if (!this.get('session.isAuthenticated')) {
                return new Ember.RSVP.Promise(function (resolve) {
                    that.get('session').authenticate('authenticator:oneCentral').then(() => {
                        console.info('Authenticated');
                        resolve({ authenticated: true });
                    }).catch(reason => {
                        console.info('Error: ' + reason);
                    });
                });
            } else {
                return this._super(...arguments);
            }
        },
        triggerAuthentication() {
            this.get('session').authenticate('authenticator:oneCentral').then(() => {
                console.info('Authenticated');
            }).catch(reason => {
                console.info('Error: ' + reason);
            });
        }
    });
});